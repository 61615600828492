export const navigation = [
  {
    text: 'Inici',
    path: '/home',
    icon: 'home'
  },
  {
    text: 'Continguts',
    icon: 'tableproperties',
    items: [
      {
        text: 'Continguts (Obert)',
        path: '/continguts',
      },
      {
        text: 'Continguts per Camins',
        path: '/contingutspercamins',
      },
      {
        text: 'Continguts per UT',
        path: '/contingutsperut',
      },
    ]
  },
  {
    text: 'Taules auxiliars',
    icon: 'folder',
    items: [
      // {
      //   text: 'Profile',
      //   path: '/profile'
      // },
      // {
      //   text: 'Tasks',
      //   path: '/tasks'
      // },
      {
        text: 'Cursos',
        path: '/cursos'
      },      
      {
        text: 'Espais Aprenentatge',
        path: '/espaisaprenentatge'
      },      
      {
        text: 'Etapes',
        path: '/etapes'
      },
      {
        text: 'Finalitats',
        path: '/finalitats'
      },
      {
        text: 'Fonts',
        path: '/fonts'
      },
      {
        text: 'Matèries',
        path: '/materies'
      },
      {
        text: 'Objectius Etapa/Matèria',
        path: '/objectiusEtapaMateria'
      },
      {
        text: 'Prioritzacions',
        path: '/prioritzacions'
      },
      {
        text: 'Seqüències De Continguts',
        path: '/sequenciesDeContinguts'
      },      
      {
        text: 'Tipologies de contingut',
        path: '/tipologiesContingut'
      },
      {
        text: 'Unitats Temporals',
        path: '/unitatsTemporals'
      }
    ]
  }
  ];
