import React from 'react';
import config from "../../appsettings.json"
import 'devextreme/data/odata/store';
import {
  Column,
  DataGrid,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  GroupPanel,
  Editing,
  Grouping,
  Lookup,
  Export,
  Selection,
  ColumnChooser,
  ColumnFixing,
  Sorting
} from 'devextreme-react/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';
import CustomStore from 'devextreme/data/custom_store';
import 'whatwg-fetch';
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

const isNotEmpty = (value) => value !== undefined && value !== null && value !== '';
 
function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}
const url = config.SERVER_URL + '/DvxContingutsRaw';
const url2 = config.SERVER_URL + '/DvxContingutsFlat';
const urlEtapes = config.SERVER_URL + '/DvxEtapes';
const urlCursos = config.SERVER_URL + '/DvxCursos';
const urlMateries = config.SERVER_URL + '/DvxMateries';
const urlSdC = config.SERVER_URL + '/DvxSequenciesDeContinguts';
const urlUTs = config.SERVER_URL + '/DvxUnitatsTemporals';
const urlTipus = config.SERVER_URL + '/DvxTipologiesContingut';
const urlPrioritats = config.SERVER_URL + '/DvxPrioritzacions';
const urlFonts = config.SERVER_URL + '/DvxFonts';
const urlEspais = config.SERVER_URL + '/DvxEspaisAprenentatge';

// const dataSource = createStore({
//   key: 'id',
//   loadUrl: `${url}`,
//   insertUrl: `${url}`,
//   updateUrl: `${url}`,
//   deleteUrl: `${url}`,
//   loadParams:{filtre:false},
//     // onBeforeSend: (method, ajaxOptions) => {
//     // ajaxOptions.xhrFields = { 
//     //   //withCredentials: true 
//     // };
//     //},
// });


const dataSource = new CustomStore({
  key: 'id',
  load(loadOptions) {
    let params = '?';
    [
      'skip',
      'take',
      'requireTotalCount',
      'requireGroupCount',
      'sort',
      'filter',
      'totalSummary',
      'group',
      'groupSummary',
    ].forEach((i) => {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) { params += `${i}=${JSON.stringify(loadOptions[i])}&`; }
    });
    params = params.slice(0, -1);
    
    return fetch(`${url}${params}`)
      .then((response) => response.json())
      .then((data) => ({
        data: data.data,
        totalCount: data.totalCount,
        summary: data.summary,
        groupCount: data.groupCount,
      }))
      .catch(() => { throw new Error('Data Loading Error'); });
  },
  insert: (values) => {
    return fetch(`${url}`, {
        method: 'POST',
        body: JSON.stringify(values),
        headers:{
            'Content-Type': 'application/json'
        }
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
},
remove: (key) => {
    return fetch(`${url}/${encodeURIComponent(key)}`, {
        method: 'DELETE',
  })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
},
update: (key, values) => {
    return fetch(`${url}/${encodeURIComponent(key)}`, {
        method: 'PUT',
        body: JSON.stringify(values),
        headers:{
            'Content-Type': 'application/json'
        }
    })
    .then(handleErrors)
    .catch(() => { throw 'Network error' });
}

});

const dataSourceEtapes = createStore({
  key: 'id',
  loadUrl: `${urlEtapes}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { 
      /* withCredentials: true*/
    };
  },
});

const dataSourceCursos = createStore({
  key: 'id',
  loadUrl: `${urlCursos}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { 
      /* withCredentials: true*/
    };
  },
});

const dataSourceMateries = createStore({
  key: 'id',
  loadUrl: `${urlMateries}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { 
      /* withCredentials: true*/
    };
  },
});

const dataSourceSdC = createStore({
  key: 'id',
  loadUrl: `${urlSdC}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { 
      /* withCredentials: true*/
    };
  },
});

const dataSourceUTs = createStore({
  key: 'id',
  loadUrl: `${urlUTs}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { 
      /* withCredentials: true*/
    };
  },
});

const dataSourceTipus = createStore({
  key: 'id',
  loadUrl: `${urlTipus}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { 
      /* withCredentials: true*/
    };
  },
});

const dataSourcePrioritats = createStore({
  key: 'id',
  loadUrl: `${urlPrioritats}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { 
      /* withCredentials: true*/
    };
  },
});

const dataSourceFonts = createStore({
  key: 'id',
  loadUrl: `${urlFonts}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { 
      /* withCredentials: true*/
    };
  },
});

const dataSourceEspais = createStore({
  key: 'id',
  loadUrl: `${urlEspais}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { 
      /* withCredentials: true*/
    };
  },
});

class Continguts extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
    this.onExporting = this.onExporting.bind(this);

    // Uncomment the line below if the function should be executed in the component's context
     this.ut_calculateCellValue = this.ut_calculateCellValue.bind(this);
  }

// eslint-disable-next-line no-unused-vars
ut_calculateCellValue(rowData) {
  return rowData.codi + ' - ' + rowData.nom;

}

  render() {
    return (
      <React.Fragment>
        <h2 className={'content-block'}>Continguts</h2>

        <DataGrid
          className={'dx-card wide-card'}
          dataSource={dataSource}
          showBorders={false}
          focusedRowEnabled={true}
          defaultFocusedRowIndex={0}
          columnAutoWidth={true}
          allowColumnResizing={true}
          wordWrapEnabled={true}
          //columnHidingEnabled={true} 
          // height={600}
          remoteOperations={true}
          onExporting={this.onExporting}          
        >

          <Selection mode="multiple" /> {/* "multiple" | "none" */}
          <Sorting mode="multiple" />
          <ColumnChooser enabled={true} />
          <ColumnFixing enabled={true} />
          <Paging defaultPageSize={200} />
          <Pager showPageSizeSelector={true} showInfo={true} />          
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <GroupPanel visible={true} />
          <Editing
            mode="row"
            allowAdding={true}
            allowDeleting={true}
            allowUpdating={true}
          />
          <Grouping autoExpandAll={false} />

          {/* <Column dataField="codi" caption="Codi">
          </Column> */}
          <Column dataField="id" caption="Id" visible={false}>
          </Column>

          <Column dataField="nom" caption="Nom"  width={400}>
          </Column>

          <Column caption="Codi Etapa" calculateCellValue={this.calculateEmptyColumn} visible={false}>
          </Column>   

          <Column dataField="idEtapa" caption="Etapa"  groupIndex={0}>
            <Lookup
              dataSource={dataSourceEtapes}
              valueExpr={'id'}
              displayExpr={'nom'}
            />
          </Column>

          <Column caption="Codi Curs" calculateCellValue={this.calculateEmptyColumn} visible={false}>
          </Column>   

          <Column dataField="idCurs" caption="Curs"  groupIndex={1}>
            <Lookup
              dataSource={dataSourceCursos}
              valueExpr={'id'}
              displayExpr={'nom'}
            />
          </Column>

          <Column caption="Codi Materia" calculateCellValue={this.calculateEmptyColumn} visible={false}>
          </Column>   

          <Column dataField="idMateria" caption="Matèria">
            <Lookup
              dataSource={dataSourceMateries}
              valueExpr={'id'}
              displayExpr={'nom'}
            />
          </Column>

          <Column caption="Codi SDC" calculateCellValue={this.calculateEmptyColumn} visible={false}>
          </Column>   

          <Column dataField="idSequenciaDeContingut" caption="SdC">
            <Lookup
              dataSource={dataSourceSdC}
              valueExpr={'id'}
              displayExpr={'nom'}
            />
          </Column>

          <Column caption="Codi UT" calculateCellValue={this.calculateEmptyColumn} visible={false}>
          </Column>   

          <Column dataField="idUnitatTemporal" caption="UT"  groupIndex={2}>
            <Lookup
              dataSource={dataSourceUTs}
              valueExpr={'id'}
              displayExpr={'codi'}
              //calculateDisplayValue={ut_calculateCellValue}
            />
          </Column>

          <Column caption="Codi Tipologia" calculateCellValue={this.calculateEmptyColumn} visible={false}>
          </Column>   

          <Column dataField="idTipologiaContingut" caption="Tipologia">
            <Lookup
              dataSource={dataSourceTipus}
              valueExpr={'id'}
              displayExpr={'nom'}
            />
          </Column>

          <Column caption="Codi Priorit." calculateCellValue={this.calculateEmptyColumn} visible={false}>
          </Column>   

          <Column dataField="idPrioritzacio" caption="Prioritat">
            <Lookup
              dataSource={dataSourcePrioritats}
              valueExpr={'id'}
              displayExpr={'nom'}
            />
          </Column>

          <Column dataField="idFont" caption="Font" visible={false}>
            <Lookup
              dataSource={dataSourceFonts}
              valueExpr={'id'}
              displayExpr={'nom'}
            />
          </Column>

          <Column dataField="idEspaiAprenentatge" caption="Espai aprenentatge">
            <Lookup
              dataSource={dataSourceEspais}
              valueExpr={'id'}
              displayExpr={'nom'}
            />
          </Column>

          <Column dataField="objectiuUT" caption="Objectiu UT"  width={200}>
          </Column>

          <Column dataField="indicadorAssoliment" caption="Indicador assoliment"  width={200}>
          </Column>

          <Export enabled={true} allowExportSelectedData={true} />
          
        </DataGrid>
      </React.Fragment>

    );
  }
  onExporting(e) {
    e.component.beginUpdate();
    e.component.columnOption('Id', 'visible', true);
    e.component.columnOption('Codi Etapa', 'visible', true);
    e.component.columnOption('Codi Curs', 'visible', true);
    e.component.columnOption('Codi Materia', 'visible', true);
    e.component.columnOption('Codi SDC', 'visible', true);
    e.component.columnOption('Codi UT', 'visible', true);
    e.component.columnOption('Codi Tipologia', 'visible', true);
    e.component.columnOption('Codi Priorit.', 'visible', true);

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Continguts');

    worksheet.columns = [  
      { width: 10 }, { width: 50 }, { width: 10 }, { width: 10 },  { width: 10 }, { width: 20 }, { width: 10 }, { width: 20 }, { width: 10 }, { width: 10 }, { width: 20 }, { width: 10 }, { width: 20 }, { width: 20 }, { width: 20 }, { width: 20 }
    ];     

    exportDataGrid({
      component: e.component,
      worksheet,
      autoFilterEnabled: true,
      keepColumnWidths: false,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Continguts.xlsx');
      });
    }).then(function() {
      e.component.columnOption('Id', 'visible', false);
      e.component.columnOption('Codi Etapa', 'visible', false);
      e.component.columnOption('Codi Curs', 'visible', false);
      e.component.columnOption('Codi Materia', 'visible', false);
      e.component.columnOption('Codi SDC', 'visible', false);
      e.component.columnOption('Codi UT', 'visible', false);
      e.component.columnOption('Codi Tipologia', 'visible', false);
      e.component.columnOption('Codi Priorit.', 'visible', false);
        e.component.endUpdate();
  });    
    
    e.cancel = true;
  }  

calculateEmptyColumn(rowData){  
  return "";
};

customizeDataExport(columns, rows){  
  columns.forEach(function(column) {
    column.width = 150;  
  });
};  
}

export default Continguts;