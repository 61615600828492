import React from 'react';
import config from "../../appsettings.json"
import 'devextreme/data/odata/store';
import {
  Column,
  DataGrid,
  Pager,
  Paging,
  FilterRow,
  HeaderFilter,
  GroupPanel,
  Editing,
  Grouping,
  Lookup
} from 'devextreme-react/data-grid';
import { createStore } from 'devextreme-aspnet-data-nojquery';

const url = config.SERVER_URL + '/DvxFinalitats';
const urlEtapes = config.SERVER_URL + '/DvxEtapes';

const dataSource = createStore({
  key: 'id',
  loadUrl: `${url}`,
  insertUrl: `${url}`,
  updateUrl: `${url}`,
  deleteUrl: `${url}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { 
      //withCredentials: true 
    };
  },
});

// const customersData = createStore({
//   key: 'Value',
//   loadUrl: `${url}/CustomersLookup`,
//   onBeforeSend: (method, ajaxOptions) => {
//     ajaxOptions.xhrFields = { withCredentials: true };
//   },
// });

const dataSourceEtapes = createStore({
  key: 'id',
  loadUrl: `${urlEtapes}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { 
      /* withCredentials: true*/
    };
  },
});

class Finalitats extends React.Component {
  render() {
    return (
      <React.Fragment>
        <h2 className={'content-block'}>Finalitats</h2>

        <DataGrid
          className={'dx-card wide-card'}
          dataSource={dataSource}
          showBorders={false}
          focusedRowEnabled={true}
          defaultFocusedRowIndex={0}
          columnAutoWidth={true}
          allowColumnResizing={true}
          wordWrapEnabled={true}
          //columnHidingEnabled={true} 
          // height={600}
          remoteOperations={true}
        >
          <Paging defaultPageSize={100} />
          <Pager showPageSizeSelector={true} showInfo={true} />          
          <FilterRow visible={true} />
          <HeaderFilter visible={true} />
          <GroupPanel visible={true} />
          <Editing
            mode="row"
            allowAdding={true}
            allowDeleting={true}
            allowUpdating={true}
          />
          <Grouping autoExpandAll={false} />

          <Column dataField="codi" caption="Codi" width="150">
          </Column>

          <Column dataField="idEtapa" caption="Etapa">
            <Lookup
              dataSource={dataSourceEtapes}
              valueExpr={'id'}
              displayExpr={'nom'}
            />
          </Column>

          <Column dataField="nom" caption="Nom">
          </Column>

        </DataGrid>
      </React.Fragment>

    );
  }
}

export default Finalitats;