import { withNavigationWatcher } from './contexts/navigation';
import { HomePage, TasksPage, ProfilePage, EtapesPage, CursosPage, FinalitatsPage, MateriesPage, 
        ObjectiusEtapaMateriaPage, PrioritzacionsPage, TipologiesContingutPage, SequenciesDeContingutsPage, UnitatsTemporalsPage,
        ContingutsPage,ContingutsCaminsPage,ContingutsUTPage, FontsPage , EspaisAprenentatgePage
       } from './pages';

const routes = [
  {
    path: '/tasks',
    component: TasksPage
  },
  {
    path: '/profile',
    component: ProfilePage
  },
  {
    path: '/home',
    component: HomePage
  },
  {
    path: '/continguts',
    component: ContingutsPage
  },
  {
    path: '/contingutspercamins',
    component: ContingutsCaminsPage
  },
  {
    path: '/contingutsperut',
    component: ContingutsUTPage
  },
  {
    path: '/etapes',
    component: EtapesPage
  },
  {
    path: '/cursos',
    component: CursosPage
  },
  {
    path: '/finalitats',
    component: FinalitatsPage
  },
  {
    path: '/materies',
    component: MateriesPage
  },
  {
    path: '/objectiusEtapaMateria',
    component: ObjectiusEtapaMateriaPage
  },
  {
    path: '/prioritzacions',
    component: PrioritzacionsPage
  },
  {
    path: '/tipologiesContingut',
    component: TipologiesContingutPage
  },
  {
    path: '/sequenciesDeContinguts',
    component: SequenciesDeContingutsPage
  },
  {
    path: '/unitatsTemporals',
    component: UnitatsTemporalsPage
  },       
  {
    path: '/fonts',
    component: FontsPage
  },  
  {
    path: '/espaisaprenentatge',
    component: EspaisAprenentatgePage
  },  
];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});
